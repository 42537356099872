// import Vue from 'vue'
import swal from 'sweetalert2'

/* eslint-disable */
export const generalMethods = {
  messageBox: (title, message, type) => {
    swal.fire({
      title: title,
      html: message,
      icon: type,
      timer: 6000,
      confirmButtonText: 'Close X',
      customClass: {
        confirmButton: 'btn oci-close-button',
      },
      buttonsStyling: false,
    })
  },

  getFormattedTime: () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    var minutes = currentTime.getMinutes();
    if (minutes < 10) {
      minutes = '0'+String(minutes)
    }
    if (hours == 12) {
      return hours + ":" + minutes + " am";
    } else {
      return hours > 12 ? hours - 12 + ":" + minutes + " pm" : hours + ":" + minutes + " am";
    }
  },  
}
/* eslint-enable */
